<template>
  <d-remote-monitoring-indicator-units />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorUnits',
};
</script>
